export const teamMemeber = [
  {
    firstName: "Ibrahim",
    name: "HAMZA",
    bio: "Fondateur de la société ORIONIS AFRICA basée a Lomé et actionnaire principal de la société ORIONIS cote d’ivoire. IBRAHIM a à son actif 20 ans d’expérience dans les secteurs des télécommunications, de la communication et dans la Fin-Tech. Diplômé de l’université du Québec à Montréal en administration des affaires et ingénieur en commerce international. Un serial entrepreneur et administrateur après son expérience corporate se lance dans l’entreprenariat et passe plus 10 ans à la tête des nombreuses entreprises du secteur. Un fédérateur avec un parcours atypique, Ibrahim a su capitaliser pour en créer une réelle valeur ajoutéepour les institutions qu’il gère et ses parties prenantes.",
    photo: "./images/photos/dg.jpg",
  },
  {
    name: "AOUELY",
    firstName: "Serge Stéphane",
    bio: "",
    photo: "./images/photos/stephane_aouely.jpg",
  },
  {
    name: "MEMEL",
    firstName: "Amari Adam Boris",
    bio: "",
    photo: "./images/photos/memel_boris.jpg",
  },
  {
    name: "KANGA",
    firstName: "Kouassi Pierre Valery",
    bio: "",
    photo: "./images/photos/_.jpg",
  },
  {
    name: "SEHR",
    firstName: "Andrée",
    bio: "",
    photo: "./images/photos/_.jpg",
  },
];
